import React, { useEffect } from "react";
import { useDencrypt } from "use-dencrypt-effect";

const decryptChars = [
    "-",
    ".",
    "/",
    "*",
    "!",
    "?",
    "#",
    "%",
    "&",
    "@",
    "$",
    "€",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
    "<",
    ">",
    "~",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
];

export const TextDecrypt = ({ text,interval}) => {
    const { result, dencrypt } = useDencrypt({ chars: decryptChars, interval });

    useEffect(() => {
        dencrypt(text || "");
    }, [dencrypt, text]);

    return (
        <p>
          {result}
        </p>
    );
};

import React  from "react";
import { makeStyles  } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as LogoSvg } from '../../assets/Alafandi.svg';

const useStyles = makeStyles((theme) => ({
  svgHover: theme.custom.svgHover,
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classes.svgHover}
      component={LogoSvg}
      viewBox="0 0 300 300"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default Logo;
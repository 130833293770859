import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import Resume from "../../settings/resume.json";
import { FirstName, LastName } from "../../utils/getName";
import { JavaAnimation, ReactAnimation } from "../icons/iconsAnim";

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: "auto",
    marginBottom: "auto",
  },
  heading: {
    marginLeft: theme.spacing(50),
    "@media (max-width: 1200px)": {
      marginLeft: theme.spacing(25),
    },
    "@media (max-width: 768px)": {
      marginLeft: theme.spacing(10),
    },
  },
  jobs: {

    "@media (max-width: 768px)": {
      fontSize: '3rem',
    },
  },
  row:{
    display: 'flex',
    alignItems:"center"

  }
  
}));

export const Content = () => {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.main} maxWidth="md">
      <div className={classes.heading}>
        <Typography variant="h5" component="h3">
            <TextDecrypt text={`${FirstName} ${LastName}`} interval={30} />
        </Typography>
        <Typography variant="h2" component="h2" className={`${classes.jobs} ${classes.row}`}>
            <TextDecrypt text={`${Resume.basics.job1}  `} interval={60}  /><JavaAnimation /><ReactAnimation />
        </Typography>
        <Typography variant="h2" component="h2" className={classes.jobs}>
            <TextDecrypt text={`${Resume.basics.job2}`} interval={85} />
        </Typography>
        <div style={{marginBottom:"10%"}}></div>
        <Typography variant="h2" component="h2" className={classes.jobs}>
            <TextDecrypt text={`${Resume.basics.job3}`} interval={85} />
        </Typography>
      </div>
    </Container>
  );
};

/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import resume from "../../settings/resume.json"

import './Works.css';

// Import ../../assets/recentprojects/
import Portfolio from '../../assets/recentprojects/react-portfolio.png';


const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();


  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {resume.projects.map((project , index) => (
          <div className="project" key={ index +1 }>
            <div className="__img_wrapper">
              <img src={ Portfolio } alt={ project.alter }/>
            </div>
            <div className="__content_wrapper">
            {project.id?<h4>Coming Soon....</h4>:<div></div> }

              <h3 className="title">
                <TextDecrypt text={ (index+1) + '. ' + project.title } />
              </h3>
              <p className="description">
                { project.description }
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};

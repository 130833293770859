import React from 'react';
import Lottie from 'lottie-react';
import javaAnimationData from './icons8-java.json'; 
import reactAnimationData from './icons8-react-native.json'; 

export const JavaAnimation = () => {
  return (
    <Lottie
      animationData={javaAnimationData}
      loop={true}
      autoplay={true}
      style={{ height: 70, width: 70 }}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice'
      }}
    />
  );
};

export const ReactAnimation = () => {
  return (
    <Lottie
      animationData={reactAnimationData}
      loop={true}
      autoplay={true}
      style={{ height: 70, width: 70 }}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice'
      }}
    />
  );
};
